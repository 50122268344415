<div class="content-heading">
  <div class="header-container">
    <h3>Reporte de Incidencias</h3>
    <small>Reporte Web</small>
  </div>
</div>

<div class="container-fluid">
  <div class="row">
    <div class="col-lg-12">
      <div class="card card-default">
        <div class="card-body">
          <div class="row">
            <div class="col-md-12 col-xs-12">
              <div class="row filter-container">
                <div class="col">
                  <span>Organizaci&oacute;n</span>
                  <select class="form-control" [(ngModel)]="organizationSelected"
                          name="RegionNameOri" required [disabled]="working" title="Organización"
                          (ngModelChange)="getRegion()">
                    <!--<option [value]="0" selected>Todos</option>-->
                    <option *ngFor="let unit of organizacionList" [value]="unit.UnitId">{{unit.UnitDescription}}</option>
                  </select>
                </div>

                <div class="col">
                  <span>Regi&oacute;n</span>
                  <select class="form-control" [(ngModel)]="regionSelected"
                          name="RegionNameOri" required [disabled]="working" title="Región"
                          (ngModelChange)="getNodes()">
                    <!--<option [value]="0" selected>Todos</option>-->
                    <option *ngFor="let unit of region" [value]="unit.UnitId">{{unit.UnitDescription}}</option>
                  </select>
                </div>

                <div class="col">
                  Fecha inicial
                  <input
                  [disabled]="working"
                    type="date"
                    class="form-control"
                    max="{{ actualDate }}"
                    [(ngModel)]="initialDate"
                    (change)="changeDate(true)"
                    title="Fecha inicial"
                  />
                </div>

                <div class="col">
                  Fecha final
                  <input
                  [disabled]="working"
                    type="date"
                    class="form-control"
                    max="{{ actualDate }}"
                    [(ngModel)]="finalDate"
                    (change)="changeDate(false)"
                    title="Fecha final"
                  />
                </div>

              </div>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="col-md-12 col-xs-12">
              <div class="row filter-container">
                <div class="col-sm-4">
                  <span>Nodo origen</span>
                  <ng-autocomplete
                    [placeholder]="'Búsqueda'"
                    [disabled]="working"
                    [data]="DestinyNode.UnitId == undefined ? nodesUser : nodesList"
                    [searchKeyword]="inputKeyword"
                    [itemTemplate]="itemInputTemplate"
                    [notFoundTemplate]="notFoundinputTemplate"
                    [debounceTime]="600"
                    [minQueryLength]="3"
                    (selected)="nodeSelection(true)"
                    (inputCleared)="clearOriginNode()"
                    [(ngModel)]="OriginNode"
                  >
                  </ng-autocomplete>
                  <ng-template #itemInputTemplate let-item>
                    <a [innerHTML]="item.UnitDescription"></a>
                  </ng-template>
                  <ng-template #notFoundinputTemplate let-notFound>
                    <div [innerHTML]="'No se encontraron coincidencias'"></div>
                  </ng-template>
                </div>

                <div class="col-sm-4">
                  <span>Nodo destino</span>
                  <ng-autocomplete
                    [placeholder]="'Búsqueda'"
                    [disabled]="working"
                    [data]="OriginNode.UnitId == undefined ? nodesUser : nodesList"
                    [searchKeyword]="inputKeyword"
                    [itemTemplate]="itemOutputTemplate"
                    [notFoundTemplate]="notFoundoutputTemplate"
                    [debounceTime]="600"
                    [minQueryLength]="3"
                    (selected)="nodeSelection(false)"
                    (inputCleared)="clearDestinyNode()"
                    [(ngModel)]="DestinyNode"
                  >
                  </ng-autocomplete>
                  <ng-template #itemOutputTemplate let-item>
                    <a [innerHTML]="item.UnitDescription"></a>
                  </ng-template>
                  <ng-template #notFoundoutputTemplate let-notFound>
                    <div [innerHTML]="'No se encontraron coincidencias'"></div>
                  </ng-template>
                </div>
                <div class="col-sm-1 bottom" [hidden]="DestinyNode.UnitId == undefined && OriginNode.UnitId == undefined">
                  <div class="checkbox c-checkbox">
                    <label>
                      <input type="checkbox" id="Todos" [checked]="chkAll" [(ngModel)]="chkAll" name="chkAll"/>
                      <span class="fa fa-check"></span>Todas
                    </label>
                  </div>      
                </div>
                <div class="col-sm-3">
                  <br>
                  <button class="mr-1 btn btn-labeled btn-outline-primary mb-2" (click)="getAffectedList()">
                    <span class="btn-label">
                      <i class="fa fa-search" aria-hidden="true"></i>
                    </span>
                    <span>Buscar</span>
                  </button>
                  <button class="mr-1 btn btn-labeled btn-outline-success mb-2" [disabled]="downloading" (click)="getIncidencesList()">
                    <span class="btn-label">
                      <i class="fa fa-file-excel" aria-hidden="true" *ngIf="!downloading"></i>
                      <i class="fa fa-circle-notch fa-spin" *ngIf="downloading"></i>
                    </span>
                    <span>Descargar reporte</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <hr>
          <div class="row">
            <div class="col-sm-6">
              <div class="row">
                <div class="col-md-8 col-xs-8 div-item-center">
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered table-hover">
                      <thead>
                        <tr>
                          <th style="border: 0cap;"></th>
                          <th colspan="2" class="background-nav-color tocenter">AFECTACION</th>
                        </tr>
                        <tr class="background-nav-color">
                          <th>Estatus</th>
                          <th>Aceptado</th>
                          <th>Cancelado</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of affectList">
                          <td>{{ item.status }}</td>
                          <td class="toright">{{ item.accept }}</td>
                          <td class="toright">{{ item.reject }}</td>
                        </tr>
                        <br>
                        <tr style="background-color: rgba(0, 0, 0, 0.777); color: white;">
                          <th>Total (suma) del rango consultado</th>
                          <th class="toright">{{ sumAccept }}</th>
                          <th class="toright">{{ sumReject }}</th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>                
              </div>
            </div>
            <div class="col-sm-6">
              <div class="row">
                <div class="col-md-11 col-xs-11">
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered table-hover">
                      <thead>
                        <tr class="background-nav-color">
                          <th>Tipo Incidencia</th>
                          <th>Abierto</th>
                          <th>Rechazado</th>
                          <th>Escalado</th>
                          <th>Cerrado</th>
                          <th>Caducado</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of incidenceList">
                          <td>{{ item.IncidenceType }}</td>
                          <td class="toright">{{ item.Opened }}</td>
                          <td class="toright">{{ item.Rejected }}</td>
                          <td class="toright">{{ item.Escaladed }}</td>
                          <td class="toright">{{ item.Closed }}</td>
                          <td class="toright">{{ item.Lapsed }}</td>
                        </tr>
                        <br>
                        <tr style="background-color: rgba(0, 0, 0, 0.777); color: white;">
                          <th>Total (suma) del rango consultado</th>
                          <th class="toright">{{ sumOpe }}</th>
                          <th class="toright">{{ sumRej }}</th>
                          <th class="toright">{{ sumEsc }}</th>
                          <th class="toright">{{ sumClo }}</th>
                          <th class="toright">{{ sumLap }}</th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
