<div class="container-fluid">
    <div class="card card-default">
        <div class="card-header">
            <h3>Reporte de saldos multicanal</h3>
        </div>
        <div class="card-body">
            <div class="col-lg-12">
                <div class="row">
                    <div class="col-lg-12">
                        <!-- start report filters -->
                        <div class="row">
                            <div class="form-group col-lg-4 col-md-6 col-sm-12">
                                <label class="form-label">Centro de ventas</label>
                                <ceq-unit-autocomplete [listType]="'CEVE'"
                                                       (onUnitClear)="onUnitClear()"
                                                       (onUnitSelected)="onUnitSelected($event)">
                                </ceq-unit-autocomplete>
                            </div>

                            <div class="form-group col-lg-4 col-md-6 col-sm-12">
                                <label class="form-label">Canal</label>
                                <select class="form-control"
                                        [(ngModel)]="channelId"
                                        (change)="onChannelSelected($event.target.value)"
                                        [disabled]="working || !selectedUnit">
                                    <option [value]="undefined" disabled *ngIf="channels.length === 0">
                                        Centro de ventas sin canales asignados
                                    </option>
                                    <option *ngFor="let channel of channels" [value]="channel.UnitId">
                                        {{channel.ChannelCode}} - {{channel.ChannelName}}
                                    </option>
                                </select>
                            </div>

                            <div class="form-group col-lg-4 col-md-6 col-sm-12">
                                <label class="form-label">Ruta</label>
                                <select class="form-control"
                                        [(ngModel)]="routeId"
                                        (change)="onRouteSelected($event.target.value)"
                                        [disabled]="working || !selectedUnit">
                                    <option [value]="undefined" disabled *ngIf="routes.length===0">
                                        Canal sin rutas
                                    </option>
                                    <option *ngFor="let route of routes" [value]="route.RouteId">
                                        {{ route.RouteName }}
                                    </option>
                                </select>
                            </div>

                        </div>
                        <div class="row">
                            <div class="form-group col-lg-3 col-md-6 col-sm-12">
                                <label class="form-label">Equipo</label>
                                <app-ceq-multiselect [Items]="containerTypeList"
                                                     (shareCheckedList)="onContainersFilterChange($event)"
                                                     (shareIndividualCheckedList)="onContainerCheckedChange($event)"
                                                     [disabled]="working || !selectedUnit"></app-ceq-multiselect>
                            </div>

                            <div class="form-group col-lg-2 col-md-6 col-sm-12">
                                <label class="form-label">Fecha inicial</label>
                                <input class="form-control"
                                       type="date"
                                       [(ngModel)]="startDate"
                                       max="{{ maxDate }}"
                                       (change)="onStartDateSelected()"
                                       [disabled]="working || !selectedUnit" />
                            </div>

                            <div class="form-group col-lg-2 col-md-6 col-sm-12">
                                <label class="form-label">Fecha final</label>
                                <input class="form-control"
                                       type="date"
                                       [(ngModel)]="endDate"
                                       max="{{ maxDate }}"
                                       (change)="onEndDateSelected()"
                                       [disabled]="working ||!selectedUnit" />
                            </div>

                        </div>
                        <!-- end report filters -->
                        <!-- start buttons -->
                        <div class="col">
                            <div class="row">
                                <div class="form-group ">
                                    <button type="button" class="mr-1 btn btn-labeled btn-outline-info form-control" (click)="Download()">
                                        <span class="btn-label">
                                            <i class="fa fa-download" aria-hidden="true" *ngIf="!working"></i>
                                            <i class="fa fa-circle-o-notch fa-spin" aria-hidden="true" *ngIf="working"></i>
                                        </span>
                                        <span>Descargar reporte</span>
                                    </button>

                                </div>
                            </div>
                        </div>
                        <!-- end buttons -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>