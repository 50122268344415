import { Directive,ElementRef, HostListener, Input  } from '@angular/core';

@Directive({
  selector: '[OnlyNumber]'
})
export class OnlyNumber {

  regexStr = '^[0-9]*$';
  constructor(private el: ElementRef) { }

  @Input() OnlyNumber: boolean;

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    let e = <KeyboardEvent> event;  
    if (this.OnlyNumber) {
      if (['Delete', 'Backspace','End','Home','ArrowLeft','ArrowRight'].indexOf(e.key) !== -1 ||
      // Allow: Ctrl+A
      (e.key == 'a' && e.ctrlKey === true) ||
      // Allow: Ctrl+C
      (e.key == 'c' && e.ctrlKey === true) ||
      // Allow: Ctrl+V
      (e.key == 'v' && e.ctrlKey === true) ||
      // Allow: Ctrl+X
      (e.key == 'x' && e.ctrlKey === true)) {
        // let it happen, don't do anything
        return;
      }
    let ch = e.key;
    let regEx =  new RegExp(this.regexStr);    
    if(regEx.test(ch))
      return;
    else
       e.preventDefault();
    }
  }

  @HostListener('paste', ['$event']) onPaste(e: ClipboardEvent) {
    const clipboardData = e.clipboardData || (<any>window).clipboardData;
    if (clipboardData) {
      const pastedText = clipboardData.getData('text');  
      let regEx =  new RegExp(this.regexStr); 
      if (regEx.test(pastedText)) {
        return;
      }else{
        e.preventDefault();
      }
    }
  }


}