import { Component, OnInit } from '@angular/core';
import { AutocompleteNode } from '../../common/ceq-unit-autocomplete/ceq-unit-autocomplete.component';

import { GlobalFunctionsService } from 'src/app/ceq/common/functions/global-functions.service';
import { MultichannelService } from '../multichannel.service';
import { SettingsService } from '../../../core/settings/settings.service';
import { CeveService } from "../../catalog/ceve/ceve.service";
import { OrgService } from '../../catalog/org/org.service';

import { UserLogin } from 'src/app/ceq/common/models/UserLogin.Model';
import { Route } from 'src/app/ceq/common/models/Route.Model';
import { Org } from "src/app/ceq/common/models/Org.Model";

interface Channel {
  ChannelCode: string;
  ChannelId: number;
  ChannelName: string;
}

interface AdjustList{
  RouteId: number;
  Item: string;
  OldQty: number;
  NewQty: number;
}

@Component({
  selector: 'app-adjust-load-order',
  templateUrl: './adjust-load-order.component.html',
  styleUrls: ['./adjust-load-order.component.scss']
})
export class AdjustLoadOrderComponent implements OnInit {

  constructor(
    private settingsService: SettingsService,
    private globalService: GlobalFunctionsService,
    private multiService: MultichannelService,
    private ceveService: CeveService,
    private orgServ: OrgService
  ) { }

  userLogin: UserLogin;
  userCeves: number[];

  ceveNode: AutocompleteNode;
  initialNode: AutocompleteNode;
  channelList: Channel[] = [];
  routes: Route[] = [];
  loadData: any[] = [];
  baseLoadData: any[] = [];
  cols: string[] = [];
  listAdjust: AdjustList[] = [];

  selectedRoute: number;
  selectedChannel: string;
  selectedChannelId: number = 0;
  noRoute: boolean = false;
  org: Org[] = [];
  orgId: number = 0;

  working: boolean = false;
  saving: boolean = false;
  currentDate: Date;
  selectedDate: string;
  filtered: boolean = false;
  loading: boolean = false;

  ngOnInit(): void {
    this.currentDate = new Date();
    this.selectedDate = this.globalService.getStringDate(this.currentDate, false, 0);
    this.userLogin = this.settingsService.getUser();
    this.getOrg();
  }

  //Gets the list of organizations
  getOrg() {
    this.loading = true;
    this.orgServ.getActiveOrgs().subscribe(data => {
      this.org = data;
      this.orgId = this.userLogin.OrgId
    });
  }
  
  /**
   * Changes data grid filtering the data by the received argument and validates if the country is selected to apply extra filter
   *
   * @param {*} event - Filter received from DOM
   * @memberof RegionListComponent
   */
  changeFilterOrg(event: any): void {
    this.orgId = event;
  }

  // Evento cuando se limpia el combo de centro de ventas
  setOutputNode(event: any){
    this.ceveNode = event;
    this.noRoute = false;
    this.getChannels();
  }

  // Evento cuando se limpia el combo de centro de ventas
  clearOutputNode() {
    this.ceveNode = this.initialNode;
  }

  /**
   * Obtiene los canales de venta asignados al centro de venta
   */
  getChannels() {
    this.channelList = [];
    this.ceveService.getChannelsbyCeve(this.ceveNode.UnitId).subscribe(data => {
      this.channelList = data;
      if(data.length > 0){
        this.selectedChannelId = 0;
        this.selectedChannel = '0';
        this.getRoutes();
      }else{
        this.noRoute = true;
        this.globalService.messageToast('No se encontraron canales asignados al centro de venta.', 'warning');
        console.log('No se encontraron canales');
      }
    });
  }

  /**
   * Permite obtener las rutas del canala seleccionado
   * @param channelid 
   */
  changeChannel(){
    this.getRoutes();
  }

  // Obtiene las rutas del canal seleccionado
  getRoutes() {
    if(this.selectedChannel != '0'){
      this.selectedChannelId = this.channelList.find(x => x.ChannelCode == this.selectedChannel).ChannelId;
    }
    this.ceveService.getCeveRoutes(this.ceveNode.UnitId, this.selectedChannelId).subscribe(data => {
      this.routes = data;
      if (data != null) {
        this.selectedRoute = 0;
        if(this.baseLoadData.length > 0){
          this.filterChannel();
        }else{
          this.getLoadData();
        }
      }
      else {
        this.selectedRoute = null;
      }      
    }, (error) => { }
      , () => { this.cmbRouteChange() });
  }

  // Filtra la tabla de rutas con la seleccionada
  cmbRouteChange() {
    this.filterRoutes();
  }

  // Obtiene los datos de carga de las rutas
  async getLoadData() {
    this.multiService.getRouteDataLoad(this.ceveNode.UnitId, this.selectedDate).subscribe(data => {
      if( data.length > 0){
        this.baseLoadData = data;
        this.cols = Object.keys(data[0]);
        this.cols = this.cols.filter(x => x != 'RUTA_ID' && x != 'CANAL');
        //console.log(this.cols);
      }
      this.loading = false;
    });
  }

  // Método que filtra las rutas
  filterRoutes() {
    if(this.filtered == false){
      if (this.selectedRoute == 0) {
        this.loadData = this.baseLoadData;
      } else {
        this.loadData = this.baseLoadData.filter(x => x.RUTA_ID == this.selectedRoute);
      }  
    }
    this.filtered = false;
  }

  // Método que filtrar la tabla de rutas con el canal seleccionado
  filterChannel() {
    this.filtered = true;
    if (this.selectedChannel == '0') {
      this.loadData = this.baseLoadData;
    } else {
      this.loadData = this.baseLoadData.filter(x => x.CANAL == this.selectedChannel);
    }
  }

  // Agrega un ajuste a la lista
  putAdjust(row: any, item: any, qty: number){
    let exist = this.listAdjust.find(x => x.RouteId == row.RUTA_ID && x.Item == item);
    if(exist != null){
      exist.NewQty = qty;
    }else{
      this.listAdjust.push({
        RouteId: row.RUTA_ID,
        Item: item,
        OldQty: row[item],
        NewQty: qty
      });
    }
    //console.log(row, item, qty,row[item]);
  }

  // Envia a guardar los datops de ajustes
  saveData() {
    if(this.listAdjust.length > 0){
      this.saving = true;
      this.multiService.saveRouteLoad(this.ceveNode.UnitId, this.selectedDate, this.listAdjust).subscribe(resp => {
        this.saving = false;
        this.getLoadData();
        this.filterChannel();
        this.globalService.messageToast('Datos guardados correctamente', 'success');
        this.listAdjust = [];
      }, (error) => {
        this.saving = false;
        this.globalService.messageToast('Error al guardar los datos', 'error');
      });
    }
  }
}
