import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../common/guards/auth-guard.guard';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { RouteBalanceComponent } from './route-balance/route-balance.component';
import { RouteBalanceReportComponent } from './route-balance-report/route-balance-report.component';
import { EquipmentReturnComponent } from './equipment-return/equipment-return.component';
import { PaybillExpiredComponent }  from './paybill-expired/paybill-expired.component';
import { AdjustLoadOrderComponent } from './adjust-load-order/adjust-load-order.component';
import { PaybillExpiredPrintComponent }  from './paybill-expired-print/paybill-expired-print.component';
import { TransferEqComponent } from './transfer-eq/transfer-eq.component';

const routes: Routes = [
  { path: 'route-balance', component: RouteBalanceComponent, canActivate: [AuthGuard] },
  { path: 'equipment-return', component: EquipmentReturnComponent,  canActivate: [AuthGuard] },
  { path: 'paybill-expired', component:  PaybillExpiredComponent, canActivate: [AuthGuard] },
  { path: 'adjust-load-order', component: AdjustLoadOrderComponent, canActivate: [AuthGuard] },
  { path: 'paybill-expired-print/:org/:orgId/:ceveId/:channelId/:collectionDate', component: PaybillExpiredPrintComponent, canActivate: [AuthGuard] },
  { path: 'transfer-eq', component: TransferEqComponent, canActivate: [AuthGuard] }
]

@NgModule({
  declarations: [RouteBalanceComponent, RouteBalanceReportComponent, EquipmentReturnComponent, PaybillExpiredComponent, AdjustLoadOrderComponent,PaybillExpiredPrintComponent, TransferEqComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    FormsModule
  ]
})
export class MultichannelModule { }