import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { NgModule } from '@angular/core';
import { UiSwitchModule } from 'ngx-ui-switch';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { MsalModule, MsalInterceptor } from '@azure/msal-angular';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';

import { CoreModule } from './core/core.module';
import { LayoutModule } from './layout/layout.module';
import { SharedModule } from './shared/shared.module';
import { RoutesModule } from './routes/routes.module';
import { InventoryModule } from './ceq/inventory/inventory.module'
import { ReportsModule } from './ceq/reports/reports-module';
import { TaxModule } from './ceq/tax/tax.module';
import { PurchaseModule } from './ceq/purchase/purchase.module';
import { MapModule } from './ceq/map/map.module';
import { MultichannelModule } from './ceq/multichannel/multichannel.module';

import { GenericService } from './ceq/common/services/GenericServices';
import { ShippingQuickPickComponent } from './ceq/self-service/balance/shipping-quick-pick/shipping-quick-pick.component';
import { DispatchManagerComponent } from './ceq/commercial/dispatch-manager/dispatch-manager.component';
import { DispatchSubmanagerComponent } from './ceq/commercial/dispatch-submanager/dispatch-submanager.component';
import { SelfServiceManagerComponent } from './ceq/commercial/self-service-manager/self-service-manager-list/self-service-manager.component';
import { SelfServiceDivisionComponent } from './ceq/commercial/self-service-division/self-service-division-list/self-service-division.component';

// Firebase
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireModule } from '@angular/fire';
import { environment } from '../environments/environment';


// https://github.com/ocombe/ng2-translate/issues/218
export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({
    declarations: [
      AppComponent,
      ShippingQuickPickComponent,
      DispatchManagerComponent,
      DispatchSubmanagerComponent,
      SelfServiceManagerComponent,
      SelfServiceDivisionComponent
    ],
  imports: [
      BrowserModule,
      UiSwitchModule,
      HttpClientModule,
      BrowserAnimationsModule, // required for ng2-tag-input
      AngularFireDatabaseModule,
      AngularFireAuthModule,
      AngularFireMessagingModule,
      AngularFireModule.initializeApp(environment.firebase),
      CoreModule,
      LayoutModule,
      SharedModule.forRoot(),
      RoutesModule,
      InventoryModule,
      ReportsModule,
      TaxModule,
      PurchaseModule,
      MapModule,
      MultichannelModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: (createTranslateLoader),
          deps: [HttpClient]
        }
      }),
    MsalModule.forRoot({
      auth: {
          //clientId: 'db262e6b-eb28-45eb-b4d1-d3903a2c403f', // PROD
          //clientId: '4581ed75-d273-41f6-81b7-6cf9844c695e', // QA
          clientId: 'da7e2345-a0cd-43c7-b7ca-dc1180040667', // DEV
          authority: 'https://login.microsoftonline.com/973ba820-4a58-4246-84bf-170e50b3152a',
          //redirectUri: 'https://ceq500.bimboconnect.com/security/login/signin-oidc'
          //redirectUri: 'https://ceq300.bimboconnect.com/security/login/signin-oidc'
          redirectUri: 'https://ceq200.bimboconnect.com/security/login/signin-oidc'
          //redirectUri: 'http://localhost:5000/security/login/signin-oidc'
        },
        cache: {
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: isIE, // set to true for IE 11
        },
      },
        {
          popUp: !isIE,
          consentScopes: [
            'user.read',
            'openid',
            'profile',
          ],
          protectedResourceMap: [
            ['Enter_the_Graph_Endpoint_Herev1.0/me', ['user.read']]
          ],
          extraQueryParameters: {}
        })
    ],
    providers: [
      {
        provide: HTTP_INTERCEPTORS,
        useClass: MsalInterceptor,
        multi: true

      },
      GenericService

    ],
    bootstrap: [
      AppComponent
    ]
  })
  export class AppModule { }
