import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { GenericService } from '../../common/services/GenericServices';
import { Customer } from '../../common/models/Customer.Model';
import { Channel } from '../../common/models/Channel.Model';
import { Route } from '../../common/models/Route.Model';
import { RouteChannelModel } from '../../common/models/RouteChannelModel';

@Injectable({
  providedIn: 'root'
})
export class EquipmentloanserviceService {

  constructor(private genericServ: GenericService) { }

  /**
  * Get  equipment loan pdf document
  *
  * @param {string} EquipmentLoanId - Equipment loan id
  * @returns {Observable<paybill>} - Equipment loan PDF
  * @memberof CustomerBalanceService
  */
  printEquipmentLoanPdf(equipmentLoanId: string): Observable<any> {
    return this.genericServ.getPdfRequest(`equipmentloan/print/${equipmentLoanId}`, {}, 0);
  }

  setEquipmentLoan(equipmentLoanId: string, body: any) {
    return this.genericServ.postRequest(`equipmentloan/set/${equipmentLoanId}`, {}, 0, body);
  }

  TransferEquipmentLoan(equipmentLoanId: string, body: any) {
    return this.genericServ.postRequest(`equipmentloan/transfer/${equipmentLoanId}`, {}, 0, body);
  }


  getChannelByCeve(ceveId: number): Observable<Channel[]> {
    let url = `equipmentloan/${ceveId}/channels`;
    return this.genericServ.getRequest(url, {}, 0);
  }

  getRoutesByChannel(ceveId: number, channelId: number): Observable<RouteChannelModel[]> {
    return this.genericServ.getRequest(`route/byChannel/${ceveId}/${channelId}`, {});
  }

  getCustomersByRoute(routeId: number): Observable<Customer[]> {
    return this.genericServ.getRequest(`customer/equipmentloan/route/${routeId}`, {}, 0);
  }

  updateExpireEqipmentLoan(level: string, expireDays: number, ceveId: number, channelId: number, routeId: number, customerId: number) {
    let data = {
      ExpireDays: expireDays,
      RouteId: routeId,
      ChannelId: channelId,
      CeveId: ceveId,
      CustomerId: customerId
    }
    return this.genericServ.putRequest(`route/expireequipmentloan/${level}`, {}, 0, data);
  }

  getExpireEqipmentLoanExcel(level: string, ceveId: number, channelId: number, routeId: number, customerId: number) {
    let url = `route/expireEqipmentLoan/report/${level}/${ceveId}/${channelId}/${routeId}/${customerId}`;
    return this.genericServ.getExcelRequest(url, {}, 0);
  }

}
