import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { SettingsService } from 'src/app/core/settings/settings.service';
import { GlobalFunctionsService } from '../functions/global-functions.service';

@Component({
  selector: 'ceq-unit-autocomplete',
  templateUrl: './ceq-unit-autocomplete.component.html',
  styleUrls: ['./ceq-unit-autocomplete.component.scss']
})
export class CeqUnitAutocompleteComponent implements OnInit {

  /**Deshabilita el control cuando esta en true */
  @Input() loading: boolean = false;
  /**Tipo de elementos mostrados, NODES muestra plantas y ceves */
  @Input() listType: AutocompleteNodeType = 'NODES';
  /**Id de organizacion, por defecto Bimbo */
  @Input() unitOrgId: number = 0;
  /**Evento que se dispara el borrar el dato del control */
  @Output() onUnitClear = new EventEmitter();
  /**Evento que se dispara al seleccionar un elemento de la lista de coincidencias */
  @Output() onUnitSelected = new EventEmitter<AutocompleteNode>();


  units: AutocompleteNode[] = [];
  selectedUnit: AutocompleteNode = undefined;



  constructor(
    private settings: SettingsService,
    private globalService: GlobalFunctionsService
  ) { }

  ngOnInit(): void {
    this.getUserProfile();

  }


  //**Evento que se dispara cuando se asigna una propiedad [] en el NgModel */
  ngOnChanges(changes: SimpleChanges) {
    //Valida que se haya cambiado la propiedad unitOrgId
    if (changes.unitOrgId?.currentValue) {
      this.getUserProfile();
    }
  }
  
  async getUserProfile() {
    let user = this.settings.getUser();

    await this.globalService.GetParentList(user.Id, this.listType)
      .toPromise()
      .then(resp => {
        //filtro nodos por la organizacion bimbo, barcel
        if(this,this.unitOrgId==0){
          this.units = resp
        }
        else{
          this.units = resp.filter(x => x.UnitOrgId == this.unitOrgId);
        }

        //busco el nodo inicial
        if (this.units.length > 0) {
          let initialNode = this.units.find(x => x.UnitId == user.UnitId);
          if (initialNode != null) {
            this.onSelected(initialNode);
          }
          else {
            //selecciono el primer nodo
            this.onSelected(this.units[0]);
          }
        }

      }).catch(() => {
        this.globalService.messageToast('No se pudieron obtener los centros de venta', 'error');
      });
  }

  /**Evento al eliminar el nodo seleccionado */
  onClear() {
    this.onUnitClear.emit();
  }

  /**Evento al seleccionar un elemento de las coincidencias */
  onSelected(event: AutocompleteNode) {
    this.selectedUnit = event;
    this.onUnitSelected.emit(event);
  }

}

export type AutocompleteNodeType = 'CEVE' | 'FAB' | 'NODES';

export class AutocompleteNode {
  UnitOrgId: number;
  UnitId: number;
  ParentId: number;
  UnitCode: string;
  LevelType: string;
  UnitDescription: string;
}