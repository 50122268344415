<ng-autocomplete class=""
                 [initialValue]=""
                 [data]="units"
                 [disabled]="loading"
                 [itemTemplate]="itemTemplate"
                 [notFoundTemplate]="notFoundTemplate"
                 [(ngModel)]="selectedUnit"
                 (inputCleared)="onClear()"
                 (selected)="onSelected($event)"
                 [searchKeyword]="'UnitDescription'"
                 [minQueryLength]="3"
                 [debounceTime]="600"
                 [placeholder]="'Seleccione una opción'">
</ng-autocomplete>

<ng-template #itemTemplate let-item>
    <a [innerHTML]="item.UnitDescription"></a>
</ng-template>

<ng-template #notFoundTemplate let-notFound>
    <div [innerHTML]="'No se encontraron coincidencias'"></div>
</ng-template>