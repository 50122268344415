import { Component, OnInit, ViewChild } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { CeqTableComponent } from '../../common/ceq-table/ceq-table.component';
import { EditChildRendered } from '../../common/renders/edit-child-rendered';
import { GlobalFunctionsService } from '../../common/functions/global-functions.service'
import { OrgService } from  '../../catalog/org/org.service';
import { Org } from "../../common/models/Org.Model";
import { Ceve } from '../../common/models/Ceve.Model';
import { ChannelService } from '../../catalog/channel/channel.service';
import { Channel } from '../../common/models/Channel.Model';
import { PaybillList } from 'src/app/ceq/common/models/PaybillList.Model';
import { MultichannelService } from '../multichannel.service';
import { PrintChildRendered } from 'src/app/ceq/common/renders/print-child-rendered';
import { PaybillService } from 'src/app/ceq/self-service/balance/paybill/paybill.service';
import { AutocompleteNode } from '../../common/ceq-unit-autocomplete/ceq-unit-autocomplete.component';
import { Router } from '@angular/router';



@Component({
  selector: 'app-paybill-expired',
  templateUrl: './paybill-expired.component.html',
  styleUrls: ['./paybill-expired.component.scss']
})
export class PaybillExpiredComponent implements OnInit {

     // ViewChild
      @ViewChild('CeqGrid', { static: false }) ceqListGrid: CeqTableComponent;
 
    loading: boolean = false;
    org: Org[] = [];
    selectedOrg: Org;
    ceveList: Ceve[] = [];
    channelList :  Channel [] =[];
    selectedChannel: Channel;
    datepipe: DatePipe = new DatePipe('en-US');
    collectionDate: Date;
    fechadia: string = moment(new Date()).format('YYYY-MM-DD');
    working: boolean = false;
    printREM: boolean = false;
    selectedCeve: AutocompleteNode;
    cevec:string ;
    


  ListOption: GridOptions;
  gridOptions: GridOptions;

  gridCeqOptions = {
    context: this,
    frameworkComponents: {
      childEditRenderer: EditChildRendered,
      childPrintRenderer: PrintChildRendered,
    },
    suppressHorizontalScroll: true,
    onRowDoubleClicked(event: any) { },
    icons: {
      sortAscending: '<i class="fa fa-sort-up" style="margin-left:10px"></i>',
      sortDescending: '<i class="fa fa-sort-down" style="margin-left:10px"></i>',
      sortUnSort: '<i class="fa fa-sort" style="margin-left:10px"></i>'
    }
  };

  PageSizes = ["10", "25", "50", "100", "1000", "Todos"];
  selectedSize: string = "10";
    columnDefs = [
      {
        headerName: 'Fecha de creación',
        field: 'CreationDate',
        width: 10,
        cellClass: 'align-center',
        editable: false,
        sortable: true,
        resizable: true,
        cellRenderer: (params: any) =>
          moment(params.data.CreationDate).format('YYYY-MM-DD'),
      },
      {
        headerName: 'Fecha de vencimiento',
        field: 'ExpirationDate',
        width: 10,
        cellClass: 'align-center',
        editable: false,
        sortable: true,
        resizable: true,
        cellRenderer: (params: any) =>
          moment(params.data.ExpirationDate).format('YYYY-MM-DD'),
      },
      {
        headerName: 'Fecha a cobro',
        field: 'CollectionDate',
        width: 10,
        cellClass: 'align-center',
        editable: false,
        sortable: true,
        resizable: true,
        cellRenderer: (params: any) =>
          moment(params.data.CollectionDate).format('YYYY-MM-DD'),
      },
      {
        headerName: 'Ruta',
        field: 'Route',
        width: 10,
        cellClass: 'align-center',
        editable: false,
        sortable: true,
        resizable: true,
      },
      {
        headerName: 'Vendedor',
        field: 'Seller',
        width: 10,
        cellClass: 'align-center',
        editable: false,
        sortable: true,
        resizable: true,
      },
      {
        headerName: 'Jefe de vendedor',
        field: 'SalesManager',
        width: 10,
        cellClass: 'align-center',
        editable: false,
        sortable: true,
        resizable: true,
      },
      {
        headerName: 'Estatus',
        field: 'Status',
        width: 10,
        cellClass: 'align-center',
        editable: false,
        sortable: true,
        resizable: true,
      },
      {
        headerName: 'MTA',
        field: 'OriginalQtyMTA',
        width: 10,
        cellClass: 'align-center',
        editable: false,
        sortable: true,
        resizable: true,
      },
      {
        headerName: 'MTB',
        field: 'OriginalQtyMTB',
        width: 10,
        cellClass: 'align-center',
        editable: false,
        sortable: true,
        resizable: true,
      },
      {
        headerName: 'TIR',
        field: 'OriginalQtyTIR',
        width: 10,
        cellClass: 'align-center',
        editable: false,
        sortable: true,
        resizable: true,
      },
      {
        headerName: 'DOLLY',
        field: 'OriginalQtyDOLLY',
        width: 10,
        cellClass: 'align-center',
        editable: false,
        sortable: true,
        resizable: true,
      },
      {
        headerName: 'TARIMA',
        field: 'OriginalQtyTARIMA',
        width: 10,
        cellClass: 'align-center',
        editable: false,
        sortable: true,
        resizable: true,
      },
      {
        headerName: 'Total Envases',
        field: '',
        width: 10,
        cellClass: 'align-center',
        editable: false,
        sortable: true,
        resizable: true,
        cellRenderer: (params: any) =>
          Math.abs(params.data.OriginalQtyMTA  + params.data.OriginalQtyMTB + params.data.OriginalQtyTIR + params.data.OriginalQtyDOLLY + params.data.OriginalQtyTARIMA)
      },
      {
        headerName: 'Importe',
        field: 'Amount',
        width: 10,
        cellClass: 'align-center',
        editable: false,
        sortable: true,
        resizable: true,
     }
    ];


    constructor(
      private multichannelService: MultichannelService,
      private OrgService: OrgService,
      private channelService: ChannelService,
      private paybillService: PaybillService,
      private global: GlobalFunctionsService,
      private router: Router,)
  {}  

  async  ngOnInit(): Promise<void> {
    let now = moment(new Date()).format('yyyy-MM-DD');
  //  this.collectionDate = now;
    await this.getFilterOrgs();
  }



  gridEmiter(gridOptions: GridOptions): void {
    this.gridOptions = gridOptions;
    this.ceqListGrid.gridOptions = this.gridOptions;
    this.ceqListGrid.endPoint = '';
    this.ceqListGrid.data = [];
  }

  /**Canales del centro de ventas seleccionado */
  async getFilterchannels(): Promise<void> {
    this.channelList = [];
    this.selectedChannel = undefined;

    if (this.selectedCeve == undefined) {
      return;
    }
    this.loading = true;

    try {
      let channels = await this.channelService.getChannelByCeve(this.selectedCeve.UnitId).toPromise();
      let filterchannels = channels.filter(x => x.ChannelCode != "561"); 
      this.channelList = filterchannels;

      this.loading = false;

    } catch (error) {
      this.loading = false;
      console.error(error);
    }
  }


     //**Obtengo las organizaciones de los nodos asignados al usuario */
  async getFilterOrgs(): Promise<void> {
    this.org = [];
    this.selectedOrg = undefined;
    this.loading = true;
    try {
      let orgs = await this.OrgService.getActiveOrgsByUserProfile().toPromise();
      this.org = orgs;
      this.selectedOrg = orgs[0];

      this.loading = false;
     //await this.changeFilterOrg();

    } catch (error) {
      this.loading = false;
      console.error(error);
    }
  }


    getPaybillListExpiredList() {

        this.paybillService
        .getPaybillListExpired(
          this.org == undefined ? 0 : this.selectedOrg.Org,
          this.selectedOrg == undefined ? 0 : this.selectedOrg.UnitId,
          this.selectedCeve == undefined ? 0 : this.selectedCeve.UnitId,
          this.selectedChannel == undefined ? 0 : this.selectedChannel.UnitId,
          this.collectionDate ==  undefined ? new Date() : this.collectionDate
         )
        .toPromise()
        .then((resp: PaybillList[]) => {
          console.log(resp);
          this.ceqListGrid.data = resp;
        })
        .catch(() => {
          this.global.messageToast('No se pudieron obtener los vales', 'error');
        })
        .finally(() => {
          {
            this.ceqListGrid.gridOptions.api.hideOverlay();
            this.working = false;
          }
        });
    }

     /**Evento que se ejecuta al borrar el filtro de ceve */
  onCeveClear() {
    this.onCeveChanged(undefined);
  }

  /**Evento que se ejecuta al cambiar el filtro de ceve */
  async onCeveChanged(unit: AutocompleteNode): Promise<void> {
    this.selectedCeve = unit;

    this.loading=true;
    
    await this.getFilterchannels();

    
    this.loading=false;
  }

     printPaybillExpired() {
     // Abrir la nueva ventana con la URL 
      window.open(`/multichannel/paybill-expired-print/${this.selectedOrg?.Org??0}/${this.selectedOrg?.UnitId??0}/${this.selectedCeve?.UnitId??0}/${this.selectedChannel?.UnitId??0}/${this.collectionDate??0}`, '_blank');
    }
}

