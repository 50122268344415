import { Component, OnInit, ViewChild } from '@angular/core';
import { SettingsService } from '../../../core/settings/settings.service';
import { PaybillExpiredPrinted } from '../../common/models/PaybillExpiredPrinted.Model';
import { MultichannelService } from '../multichannel.service';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { UserLogin } from 'src/app/ceq/common/models/UserLogin.Model';
import { unit } from '../../catalog/factory/factory-detail/factory-detail.component';
import { PaybillService } from '../../self-service/balance/paybill/paybill.service';
import { CeqTableComponent } from '../../common/ceq-table/ceq-table.component';
import * as moment from 'moment';
import { GridOptions } from 'ag-grid-community';
import { EditChildRendered } from '../../common/renders/edit-child-rendered';
import { PrintChildRendered } from 'src/app/ceq/common/renders/print-child-rendered';
import { PaybillList } from 'src/app/ceq/common/models/PaybillList.Model';
import { CeveService } from '../../catalog/ceve/ceve.service';
import { GlobalFunctionsService } from '../../common/functions/global-functions.service';
import { Ceve } from "../../common/models/Ceve.Model";
import Swal from 'sweetalert2';

const levelType: string = 'CEVE'; // Corresponds with level type in CeqLevel table

@Component({
  selector: 'app-paybill-expired-print',
  templateUrl: './paybill-expired-print.component.html',
  styleUrls: ['./paybill-expired-print.component.scss']
})

export class PaybillExpiredPrintComponent implements OnInit {
  unit: unit[] = [];
  PaybillExpiredPrinted: PaybillExpiredPrinted[] = [];

  dateDay : string= "";
  Ceve: string;
  org: string;
  channel:string;
  collectionDate: string;

  ceveid:number;
  orgid: string;
  loading: boolean = false;
  working: boolean = false;

  ceve: Ceve = new Ceve();

  userLogin: UserLogin;
  datepipe: DatePipe = new DatePipe('en-US');

  orgn: any;
  Name: string;
  ListOption: GridOptions;
  gridOptions: GridOptions;

  gridCeqOptions = {
    context: this,
    frameworkComponents: {
      childEditRenderer: EditChildRendered,
      childPrintRenderer: PrintChildRendered,
    },
    suppressHorizontalScroll: true,
    onRowDoubleClicked(event: any) { },
    icons: {
      sortAscending: '<i class="fa fa-sort-up" style="margin-left:10px"></i>',
      sortDescending: '<i class="fa fa-sort-down" style="margin-left:10px"></i>',
      sortUnSort: '<i class="fa fa-sort" style="margin-left:10px"></i>'
    }
  };

     // ViewChild
    //  columnTable = ["Fecha de creación", "Fecha de vencimiento", "Fecha a cobro", "Ruta", "Vendedor", "Jefe de vendedor", "Estatus","MTA","MTB","TIR","DOLLY","TARIMA","Total envases","Importe"];
    //  columnChange = ["Fecha de creación", "Fecha de vencimiento", "Fecha a cobro", "Ruta", "Vendedor", "Jefe de vendedor", "Estatus","MTA","MTB","TIR","DOLLY","TARIMA","Total envases","Importe"];
    //  columnRemissionChange = ["Fecha de creación", "Fecha de vencimiento", "Fecha a cobro", "Ruta", "Vendedor", "Jefe de vendedor", "Estatus","MTA","MTB","TIR","DOLLY","TARIMA","Total envases","Importe"];
     @ViewChild('CeqGrid', { static: false }) ceqListGrid: CeqTableComponent;

     columnDefs = [
      {
        headerName: 'Fecha de creación',
        field: 'CreationDate',
        width: 10,
        cellClass: 'align-center',
        editable: false,
        sortable: true,
        resizable: true,
        cellRenderer: (params: any) =>
          moment(params.data.CreationDate).format('YYYY-MM-DD'),
      },
      {
        headerName: 'Fecha de vencimiento',
        field: 'ExpirationDate',
        width: 10,
        cellClass: 'align-center',
        editable: false,
        sortable: true,
        resizable: true,
        cellRenderer: (params: any) =>
          moment(params.data.ExpirationDate).format('YYYY-MM-DD'),
      },
      {
        headerName: 'Fecha a cobro',
        field: 'CollectionDate',
        width: 10,
        cellClass: 'align-center',
        editable: false,
        sortable: true,
        resizable: true,
        cellRenderer: (params: any) =>
          moment(params.data.CollectionDate).format('YYYY-MM-DD'),
      },
      {
        headerName: 'Ruta',
        field: 'Route',
        width: 10,
        cellClass: 'align-center',
        editable: false,
        sortable: true,
        resizable: true,
      },
      {
        headerName: 'Vendedor',
        field: 'Seller',
        width: 10,
        cellClass: 'align-center',
        editable: false,
        sortable: true,
        resizable: true,
      },
      {
        headerName: 'Jefe de vendedor',
        field: 'SalesManager',
        width: 10,
        cellClass: 'align-center',
        editable: false,
        sortable: true,
        resizable: true,
      },
      {
        headerName: 'Estatus',
        field: 'Status',
        width: 10,
        cellClass: 'align-center',
        editable: false,
        sortable: true,
        resizable: true,
      },
      {
        headerName: 'MTA',
        field: 'OriginalQtyMTA',
        width: 10,
        cellClass: 'align-center',
        editable: false,
        sortable: true,
        resizable: true,
      },
      {
        headerName: 'MTB',
        field: 'OriginalQtyMTB',
        width: 10,
        cellClass: 'align-center',
        editable: false,
        sortable: true,
        resizable: true,
      },
      {
        headerName: 'TIR',
        field: 'OriginalQtyTIR',
        width: 10,
        cellClass: 'align-center',
        editable: false,
        sortable: true,
        resizable: true,
      },
      {
        headerName: 'DOLLY',
        field: 'OriginalQtyDOLLY',
        width: 10,
        cellClass: 'align-center',
        editable: false,
        sortable: true,
        resizable: true,
      },
      {
        headerName: 'TARIMA',
        field: 'OriginalQtyTARIMA',
        width: 10,
        cellClass: 'align-center',
        editable: false,
        sortable: true,
        resizable: true,
      },
      {
        headerName: 'Total Envases',
        field: '',
        width: 10,
        cellClass: 'align-center',
        editable: false,
        sortable: true,
        resizable: true,
        cellRenderer: (params: any) =>
          Math.abs(params.data.OriginalQtyMTA  + params.data.OriginalQtyMTB + params.data.OriginalQtyTIR + params.data.OriginalQtyDOLLY + params.data.OriginalQtyTARIMA)
      },
      {
        headerName: 'Importe',
        field: 'Amount',
        width: 10,
        cellClass: 'align-center',
        editable: false,
        sortable: true,
        resizable: true,
       }
    ];     

  constructor(
    private router: ActivatedRoute, 
    private paybillService: PaybillService, 
    private multichannelService: MultichannelService, 
    private settings: SettingsService,
    private globalService: GlobalFunctionsService, 
  private ceveservice: CeveService,) {}

  ngOnInit(): void {


    this.dateDay = this.datepipe.transform(new Date(), 'dd-MM-yyyy HH:mm:ss');

    this.orgid= this.router.snapshot.params['org'] ;
    this.org =this.router.snapshot.params['orgId'] ;
    this.Ceve = this.router.snapshot.params['ceveId'];
    this.channel =this.router.snapshot.params['channelId'];
    this.collectionDate =this.router.snapshot.params['collectionDate'] ;

    this.getPaybillListExpiredList();

    this.ceveservice.GetCeveById(parseInt(this.Ceve)).subscribe(data => {
    this.ceve = data;

    });
    }

    getPaybillListExpiredList() {

      this.paybillService
      .getPaybillListExpired(
        this.orgid= this.router.snapshot.params['org'] ,
        this.org =this.router.snapshot.params['orgId'] ,
         this.ceve = this.router.snapshot.params['ceveId'],
         this.channel =this.router.snapshot.params['channelId'],
         this.collectionDate =this.router.snapshot.params['collectionDate'] 
       )
      .toPromise()
      .then((resp: PaybillList[]) => {
        console.log(resp);
        this.ceqListGrid.data = resp;
      })
      .catch(() => {

      })
      .finally(() => {
        {
          this.ceqListGrid.gridOptions.api.hideOverlay();
          this.working = false;
        }
      });
  }

  gridEmiter(gridOptions: GridOptions): void {
    this.gridOptions = gridOptions;
    this.ceqListGrid.gridOptions = this.gridOptions;
    this.ceqListGrid.endPoint = '';
    this.ceqListGrid.data = [];
  }


  getPaybillExpiredPrintedPdf(){

    // Swal.fire({
    //   title: 'Cargando Archivo...',
    //   html: 'Por favor espere',
    //   didOpen: () => {
    //     Swal.showLoading()
    //   }
    // })
    // this.remissionServ.getRemissionPrintedPdf(this.remissionNumber, true).subscribe(resp => {
    //   if (resp == null) {
    //     console.log("NODATA");
    //     Swal.close();
    //     return;
    //   }
    //   console.log(resp);
    //   let blob = new Blob([resp.body], { type: 'application/pdf' });
    //   var fileUrl = URL.createObjectURL(blob);
    //   window.open(fileUrl).print();
    //   Swal.close();
    // }, err => {
    //   console.log(err);
    //   Swal.close();
    // });
  } 

  backClick(): void {
    window.close();
  }

  /**
   * Print document
   *
   */
  print(): void {
    this.getPaybillExpiredPrintedPdf();
  }
}

