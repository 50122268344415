<div class="content-heading">
  <div class="header-Container">
    <h3>Archivos Adjuntos</h3>
    <small>{{subtitulo}}</small>
  </div>
</div>
<div class="form-container">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 col-xs-12">
        <app-ceq-table [gridAdditionalOptions]="gridOptions" [columnDef]="columnDefs" (apiEmitter)="ListEmiter($event)" #CeqGrid>
        </app-ceq-table>
      </div>
      <div class="col-sm-12">
        <br>
        <button class="mr-1 btn btn-labeled btn-outline-primary" (click)="waveLoad.click()" *ngIf="canCreate">
          <span class="btn-label">
          <i class="fa fa-file-upload " *ngIf="!Working"></i>
          <i class="fa fa-circle-o-notch fa-spin" *ngIf="Working"></i>
          </span>
          <span>Nuevo archivo adjunto...</span>
        </button>
        &nbsp;&nbsp;&nbsp;

        <input type="file" (change)="onWaveLoadSelect($event.target.files)" #waveLoad hidden
        accept=".xls,.xlsx,.doc,.docx,.pdf,.png,.xml,.txt,.jpg,.msg,.jpeg,.bmp,.gif,.eml" id="waveLoad" name="waveLoad" />

        <button type="button" class="mr-1 btn btn-labeled btn-outline-danger"  (click)="btnCancelOnClick()">
          <span class="btn-label">
            <i class="fa fa-chevron-circle-left" aria-hidden="true"></i>
          </span>
          <span>Volver</span> 
        </button>
      </div>
      <br>
      <div class="col-sm-12" [hidden]="!isImage && !isText">
        <br>
        <div style="text-align: center; border: 2px; border-style: solid; border-color: black;">
          <h2 style="color: black;">{{title}}</h2><br>
          <!--<iframe class="e2e-iframe-trusted-src" sandbox="allow-same-origin allow-popups-to-escape-sandbox allow-scripts" 
            [src]="trustedUrl" width="100%" height="400" frameborder="2"></iframe>-->
          <img [src]="img" class="image" [alt]="title" style="align-self: center; border: 20px;" [hidden]="isText">
          <h3 [hidden]="isImage">{{contentString}}</h3>
        </div>
      </div>
      <div class="col-12" [hidden]="!showIframe">
        <section class="pb-4">
          <div class="border rounded-5">
            <section class="p-4 text-center pb-4 w-100">
              <div class="embed-responsive embed-responsive-16by9">
                <iframe class="embed-responsive-item" [src]="sanitizedUrl" height="400" ></iframe>
              </div>
            </section>
          </div>
        </section>
      </div>
    </div>
  </div>
</div>
