import { Params } from '../models/response'
import { environment } from '../../../../environments/environment';
import { Injectable , Inject } from '@angular/core';
import axios, {AxiosResponse} from 'axios';

/**
 * Servicio Consulta APIRest,
 * Permite obtener los datos de los servicios API
*/

@Injectable({ providedIn: 'root' })
export class ApiService {
  public uri: string;
  public dataJSon: any | null = null;
  public method: string;
  public invokeStatus: string | null = null;
  
  
  private APIKEY: string = `${environment.apiKey}`;
 
  constructor(@Inject('uri') public Uri: string,
              @Inject('method') public Method: string) {
    this.uri = Uri;
    this.method = Method;
  }

  public async getJSonResponse(): Promise<any> {
    let responseFromServer = '';

    try {
        let user = JSON.parse(localStorage.getItem("user"));
        const myClient = axios.create({
            baseURL: this.uri,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': user.Token,
                'ApiKey':this.APIKEY,
                'CeqUser': user.Email,
                'CeqToken': user.Token,
                'ClientOrigin': 'WEB',
            }
        });

        let response: AxiosResponse;
        if (this.method === "GET") {
            response = await myClient.get(this.uri);
        } else if(this.method === "POST") {
          response = await myClient.post(this.uri, this.dataJSon);
        } else if(this.method === "PUT") {
            response = await myClient.put(this.uri, this.dataJSon);
        }

        responseFromServer = response.data;
    } catch (ex) {
        console.error("Application_Error: GetJSonResponse ", ex);
        responseFromServer = JSON.stringify({ Success: 0, Message: ex });
    }

    return responseFromServer;
}
}

@Injectable({ providedIn: 'root' })
export class ProxyGet {
  //Funcion de consulta API para obtner sucursales
    private responseJSon: any | null = null;
    private WcfeCommerce: ApiService | null = null;
    private URLResponse: string = environment.production ? `${window.location.protocol}//${window.location.host}${environment.services_url}${environment.service_version}`: `${environment.services_url}${environment.service_version}`;

    public getWcfeCommerce1(): ApiService | null {
        return this.WcfeCommerce;
    }

    public setWcfeCommerce1(value: ApiService): void {
        this.WcfeCommerce = value;
    }

    public getJson(): any {
        return this.WcfeCommerce.dataJSon;
    }

    public setJson(value: any): void {
        this.WcfeCommerce.dataJSon = value;
    }

    

    public async callAPI(service: string, method: string): Promise<any> {
        try {
          this.setWcfeCommerce1(new ApiService( `${this.URLResponse}${service}`,method));
          this.responseJSon = await this.getWcfeCommerce1()!.getJSonResponse();
          return this.responseJSon;
          
        } catch (ex) {
            console.error("Application_Error: ProxyGet-Controller ", ex);
        }
        
    }

    public async callAPIWithParams(service: string, method: string, param: Params): Promise<any> {
        const requestJSon: Record<string, any> = {};
        try {
            const requestJSon2: Record<string, any> = {};
            for (const p of param.Parameter) {
                requestJSon[p.Field] = p.Object ?? p.Value;
            }

            requestJSon2["Args"] = requestJSon;
            
            this.setWcfeCommerce1(new ApiService(`${this.URLResponse}${service}`,method));
            this.setJson(requestJSon2);
            this.responseJSon = await this.getWcfeCommerce1()!.getJSonResponse();
            return this.responseJSon;
        } catch (ex) {
            console.error("Application_Error: ProxyGet-Controller ", ex);
        }
        
    }
}