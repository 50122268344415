<div class="content-heading">
  <div class="header-Container">
    <h3>Vales de equipo vencidos</h3>
    <small>Vales vencidos</small>
  </div>
</div>
<div class="container-fluid">
  <div class="row">
      <div class="col-lg-12">
          <div class="card card-default">
              <div class="card-body">
                  <div class="row">
                      <div class="col-md-12 col-xs-12">
                          <div class="row filter-container">                      
                            <div class="col-sm-4">     
                              <span>Fecha</span>                 
                              <input type="date" class="form-control" [value]="fechadia" disabled>
                            </div>
                              <div class="col-sm-4">                      
                                  <span>Organizaci&oacute;n</span>
                                  <select [(ngModel)]="selectedOrg" class="form-control" [disabled]="loading">
                                    <!-- <select class="form-control" (ngModelChange)="changeFilterOrg()" [disabled]="loading"> -->
                                        <option *ngFor="let unit of org" [ngValue]="unit">{{unit.OrgName}}</option>
                                    </select>
                              </div>
                              <div class="col-sm-4">
                                <label for="" class="form-label">Centro de ventas</label>
                                <ceq-unit-autocomplete [listType]="'CEVE'"
                                                       [loading]="loading"
                                                       [unitOrgId]="selectedOrg?.UnitId"
                                                       (onUnitClear)="onCeveClear()"
                                                       (onUnitSelected)="onCeveChanged($event)">
                                </ceq-unit-autocomplete>
                              </div>   

                              <div class="col-sm-4"> 
                              <span>Canal</span>
                                  <select class="form-control"  [disabled]="loading">
                                    <option [value]="undefined" selected>Todos</option>
                                    <option *ngFor="let channel of channelList" >{{channel.ChannelName}}</option>
                                  </select>
                              </div>
                              <div class="col-sm-4"> 
                                <span>Fecha a Cobro</span>
                                  <input type="date" class="form-control" [(ngModel)]="collectionDate" (ngModelChange)="getPaybillListExpiredList()" [disabled]="loading">
                              </div> 
                              <div class="col-sm-4"> 
                                <br>
                                <button type="submit" class="btn btn-labeled btn-outline-success mb-2" (click)="printPaybillExpired()">                                            <span class="btn-label"><i class="fa fa-file-pdf-o" aria-hidden="true"></i></span>
                                  <span>Exportar a PDF</span>
                                </button>
                              </div>
                            </div>                         
                          <br>                    
                          <div>
                            <app-ceq-table #CeqGrid [gridAdditionalOptions]="gridCeqOptions" [columnDef]="columnDefs" (apiEmitter)="gridEmiter($event)">            
                            </app-ceq-table>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>


