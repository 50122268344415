import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericService } from '../../common/services/GenericServices';
import { Org } from '../../common/models/Org.Model';

@Injectable({
  providedIn: 'root'
})
export class OrgService {
  constructor(private genericService: GenericService) { }

  GetOrgs(): Observable<Org[]> {
    let url = "org";
    return this.genericService.getRequest(url, {});
  }

  getActiveOrgs(): Observable<Org[]> {
    let url = "org/active";
    return this.genericService.getRequest(url, {});
  }

  getActiveOrgsByUserProfile(): Observable<Org[]> {
    let url = "org/active/byuserprofile";
    return this.genericService.getRequest(url, {});
  }

  GetOrgById(orgId: number): Observable<Org> {
    let url = "org";
    return this.genericService.getRequest(url, {}, orgId);
  }

  GetOrgByParent(parentUnitId: number): Observable<Org[]> {
    let url = "org/getbyparent/" + parentUnitId;
    return this.genericService.getRequest(url, {});
  }

  CreateOrg(org: Org, parentId: number): Observable<Org> {
    let url = `org/${parentId}`;
    return this.genericService.postRequest(url, {}, 0, org);
  }

  UpdateOrg(orgId: number, parentUnit: number,org: Org): Observable<Org> {
    return this.genericService.putRequest(`org/${orgId}/${parentUnit}`, {}, 0, org);
  }

  DeleteOrg(orgId: number): Observable<boolean> {
    let url = "org/" + orgId;
    return this.genericService.deleteRequest(url, {}, 0);
  }

  SwitchOrg(orgId: number): Observable<boolean> {
    let url = "org/" + orgId + "/switch";
    return this.genericService.putRequest(url, {}, 0, null);
  }

  getCountry(){
    let url = "unit/type/PAIS";
    return this.genericService.getRequest(url,{},0);
  }

  /**
   * Gets all nodes assigned to an user
   */
  getUserOrgNodes(userId: number, level: string): Observable<any> {
    return this.genericService.getRequest(`userprofile/lvl/${level}/${userId}`, {});
  }
}
