
<div class="content-heading">
    <div class="header-Container">
        <h3>Devoluci&oacute;n de equipo</h3>
        <small>Devoluci&oacute;n en Rutas Multicanal</small>
    </div>
</div>


<div class="container-fluid">
    <div class="card card-default">
        <div class="card-body">
            <!-- inicio filtros -->
            <div class="row">
                <div class="form-group col-4">
                    <label for="" class="form-label">Fecha</label>
                    <input type="date" class="form-control" [min]="minDate" [max]="maxDate" [value]="selectedDate" disabled>
                </div>

                <div class="form-group col-4">
                    <label for="" class="form-label">Organizacion</label>
                    <select [(ngModel)]="selectedOrg" class="form-control" [disabled]="loading">
                        <option *ngFor="let o of orgList" [ngValue]="o">{{o.OrgName}}</option>
                    </select>
                </div>

                <div class="form-group col-4">
                    <label for="" class="form-label">Centro de ventas</label>
                    <ceq-unit-autocomplete [listType]="'CEVE'"
                                           [loading]="loading"
                                           [unitOrgId]="selectedOrg?.UnitId"
                                           (onUnitClear)="onCeveClear()"
                                           (onUnitSelected)="onCeveChanged($event)">
                    </ceq-unit-autocomplete>
                </div>

                <div class="form-group col-4">
                    <label for="" class="form-label">Canal</label>
                    <select [(ngModel)]="selectedChannel" class="form-control" (ngModelChange)="getFilterRoutes()" [disabled]="loading">
                        <option [ngValue]="undefined">Todos</option>
                        <option *ngFor="let c of channelList" [ngValue]="c">{{c.ChannelName}}</option>
                    </select>
                </div>

                <div class="form-group col-4">
                    <label for="" class="form-label">Ruta</label>
                    <select [(ngModel)]="selectedRoute" class="form-control" (ngModelChange)="onRouteChanged()" [disabled]="loading">
                        <option [ngValue]="undefined">Todos</option>
                        <option *ngFor="let r of routeList" [ngValue]="r">{{r.RouteName}}</option>
                    </select>
                </div>

            </div>
            <!-- fin filtros -->

            <hr> <!-- separador -->
            <app-loading *ngIf="loading"></app-loading>

            <!-- inicio listas de rutas -->
            <div class="row" *ngIf="!loading">
                <!-- inicio rutas sincronizadas -->
                <div class="col-3 border-right">
                    <h3 class="text-center">Rutas Sincronizadas</h3>
                    <div style="max-height: 500px; overflow: auto;">
                        <table class="table table-striped">
                            <thead class="table-head-sticky background-nav-color">
                                <tr>
                                    <th scope="col">
                                        <h4 class="m-0 text-center">Ruta</h4>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let r of RoutesSync" class="hover-item-list" (click)="SyncRouteClick(r.RouteId, r.RouteCode)">
                                    <td>
                                        <h4 class="m-0 text-center" data-toggle="tooltip" [title]="r.RouteName">{{r.RouteCode}}</h4>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <!-- fin rutas sincronizadas -->

                <!-- inicio rutas recibidas  -->
                <div class="col-9">
                    <h3 class="text-center">Rutas Recibidas</h3>


                    <div style=" max-height: 500px;width: 100%; overflow: auto;">
                        <table class="table table-striped m-auto" style="width: max-content;">
                            <thead class="table-head-sticky background-nav-color">
                                <tr class="">
                                    <th class=" table-cell">
                                        <h4 class="m-0 text-center">Total</h4>
                                    </th>
                                    <th class=" table-cell" *ngFor="let c of totalContainers">
                                        <h4 class="m-0 text-center" data-toggle="tooltip" [title]="c.ContainerCode">{{c.Total}}</h4>
                                    </th>
                                </tr>
                                <tr class="">
                                    <th class=" table-cell">
                                        <h4 class="m-0 text-center">Ruta</h4>
                                    </th>
                                    <th class=" table-cell" *ngFor="let c of managedContainers">
                                        <h4 class="m-0 text-center">{{c.ContainerCode}}</h4>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let r of RoutesReceived" class="">
                                    <th class=" table-cell">
                                        <h4 class="m-0 text-center" data-toggle="tooltip" [title]="r.RouteName">{{r.RouteCode}}</h4>
                                    </th>
                                    <th class=" table-cell" *ngFor="let c of r.Containers">
                                        <h4 class="m-0 text-center">{{c.Load}}</h4>
                                    </th>
                                </tr>
                            </tbody>
                        </table>

                    </div>

                </div>
                <!-- fin rutas recibidas -->
            </div>
            <!-- fin listas de rutas -->
        </div>
    </div>
</div>
<div class="modal fade" bsModal #acceptModal="bs-modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="modal-title font-weight-bold" id="exampleModalLongTitle">{{titleModal}}</h3>
                <button type="button"  class="close" aria-label="Close" (click)="acceptModal.hide()">
                <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <table class="table table-sm equipoClass">
                    <thead>
                    <tr class="table-danger">
                        <th scope="col">Equipo 
                            <button type="button" class="btn btn-default" [hidden]="typeDatTable[0]" (click)="updateTable(true,'ContainerCode')">
                                <span class="fa fa-caret-up"></span>
                            </button>
                            <button type="button" class="btn btn-default" [hidden]="!typeDatTable[0]" (click)="updateTable(false,'ContainerCode')">
                                <span class="fa fa-caret-down"></span>
                            </button>
                        </th>
                        <th scope="col">Devolución
                            <button type="button" class="btn btn-default" [hidden]="typeDatTable[1]" (click)="updateTable(true,'Unload')">
                                <span class="fa fa-caret-up"></span>
                            </button>
                            <button type="button" class="btn btn-default" [hidden]="!typeDatTable[1]" (click)="updateTable(false,'Unload')">
                                <span class="fa fa-caret-down"></span>
                            </button>
                        </th>
                        <th scope="col">Entregado</th>
                        <th scope="col">Diferencia
                            <button type="button" class="btn btn-default" [hidden]="typeDatTable[2]" (click)="updateTable(true,'Diff')">
                                <span class="fa fa-caret-up"></span>
                            </button>
                            <button type="button" class="btn btn-default" [hidden]="!typeDatTable[2]" (click)="updateTable(false,'Diff')">
                                <span class="fa fa-caret-down"></span>
                            </button>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let data of ceqGrid; index as i">
                        <td>{{data.ContainerCode}}</td>
                        <td>{{data.Unload}}</td>
                        <td>
                            <span *ngIf="!validateModal">{{data.DeliveryToReceipt}}</span>
                            <input *ngIf="validateModal" type="text" OnlyNumber="true" [id]="'Ident_' + data.ContainerCode" class="form-control input-number" [(ngModel)]="data.VanStock" (ngModelChange)="changeRange($event, data.ContainerCode)" (keyup)="changeInput($event, i)" autocomplete="off" >
                        </td>
                        <td>
                            <span *ngIf="!validateModal">{{data.DeliveryToReceipt - data.Unload}}</span>
                            <span *ngIf="validateModal">{{data.Diff}}</span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="acceptModal.hide()">Cerrar</button>
                <button type="button" class="btn btn-primary" (click)="saveData()" *ngIf="validateModal">Salvar Cambios</button>
            </div>
        </div>
    </div>
</div>