import { Injectable } from '@angular/core';
import { GenericService } from '../../common/services/GenericServices';
import { Observable } from 'rxjs';

import { Ceve } from "../../common/models/Ceve.Model";
import { Address } from '../../common/models/Address.Model';
import { CeveExternalCode } from '../../common/models/CeveExternalCode.Model';

@Injectable({
  providedIn: 'root'
})
export class CeveService {
  constructor(private genericService: GenericService) { }

  GetCeves(): Observable<Ceve[]> {
    let url = "ceve"
    return this.genericService.getRequest(url, {}, 0);
  }

  GetCeveById(ceveId: number): Observable<any> {
    let url = "ceve/" + ceveId;
    return this.genericService.getRequest(url, {}, 0);
  }

  CreateCeve(ceve: Ceve): Observable<any> {
    let url = "ceve"
    return this.genericService.postRequest(`ceve`, {}, 0, ceve);
  }

  UpdateCeve(ceve: Ceve): Observable<any> {
    let url = "ceve"
    return this.genericService.putRequest(url, {}, 0, ceve);
  }

  DeleteCeve(ceveId: number): Observable<boolean> {
    let url = "ceve/" + ceveId;
    return this.genericService.deleteRequest(url, {}, 0);
  }

  getParentLevel(orgId: number, level: string): Observable<any> {
    return this.genericService.getRequest(`level/parent/${orgId}/${level}`, {}, 0);
  }

  getParents(orgId: number, unitId: number): Observable<any> {
    return this.genericService.getRequest(`unit/${orgId}/${unitId}/parents`, {}, 0);
  }

  getCevesByParent(unitId: number, org: number): Observable<any> {
    let url = "ceve/getbyparent/" + unitId;
    return this.genericService.getRequest(url, {}, org);
  }

  getCevesByParentManagement(unitId: number, org: number): Observable<any> {
    let url = "ceve/getbyparentManagement/" + unitId;
    return this.genericService.getRequest(url, {}, org);
  }

  getCevesActive(): Observable<any> {
    let url = "ceve/actives";
    return this.genericService.getRequest(url, {}, 0);
  }

  getCeves(): Observable<any> {
    let url = "ceve";
    return this.genericService.getRequest(url, {}, 0);
  }

  getCeveRoutes(ceveId: number, channelId: number): Observable<any> {
    //let url = `ceve/${ceveId}/routes`;
    let url = `route/byChannel/${ceveId}/${channelId}`;
    return this.genericService.getRequest(url, {}, 0);
  }

  getCeveCrossCustomerRoutes(ceveId: number): Observable<any>{
    let url = `ceve/${ceveId}/routes/crosscustomer`;
    return this.genericService.getRequest(url, {}, 0);
  }

  getCeveCombo(): Observable<any> {
    let url = "ceve/combo";
    return this.genericService.getRequest(url, {}, 0);
  }

  getAddress(org: number, unitId: number): Observable<any>{
    return this.genericService.getRequest(`address/${org}/${unitId}`,{},0);
  }

  getListZipCode(zipCode: string, org: number): Observable<any>{
    return this.genericService.getRequest(`ZipCode/${zipCode}/${org}/list`,{},0);
  }

  saveAddress(address: Address): Observable<any>{
    return this.genericService.postRequest(`address`,{},0,address);
  }

  getCevesByExternalCode(org:number,systemName:string,unitId:number): Observable<any>{
    return this.genericService.getRequest(`ceveexternalcode/${org}/${systemName}/external/${unitId}`,{},0);
  }

  addCeveExternalCode(externalCode:string,originalUnitId:number,Ceve:CeveExternalCode): Observable<any>{
    return this.genericService.postRequest(`ceveexternalcode/${originalUnitId}/${externalCode}`,{},0,Ceve);
  }

  deleteCeveExternalCode(org:number,systemName:string,unitId:number,externalCode:string): Observable<any>{
    return this.genericService.deleteRequest(`ceveexternalcode/${org}/${systemName}/${unitId}/${externalCode}`,{},0);
  }

  getTransitStatus(unitId: number){
    return this.genericService.getRequest(`unit/transitRemission/${unitId}`,{},0);
  }

  updateTransitStatus(unitId: number, transitRemission: any): Observable<boolean>{
    return this.genericService.putRequest(`unit/transitRemission/${unitId}`, {}, 0, transitRemission);
  }

  validateHasBalanceInventory(unitId: number){
    return this.genericService.getRequest(`inventory/validateBalanceInventory/${unitId}`,{},0);
  }

  getSystemList(): Observable<any> {
    return this.genericService.getRequest(`managersystem`, {}, 0);
  }

  validateFabCevesAcopio(unitId: number){
    return this.genericService.getRequest(`ceves/validateCevesAcopio/${unitId}`,{},0);
  }

  getChannelsbyCeve(ceveId: number){
    return this.genericService.getRequest(`route/channel/${ceveId}`,{},0);
  } 
}
