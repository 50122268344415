import { BaseModel } from './BaseModel.Model';
export class Unit extends BaseModel {
  OrgId: number;
  UnitId: number;
  ParentId: number;
  UnitCode: string;
  LevelType: string;
  UnitDescription: string;
  /**Id del nodo organizacion 100=Bimbo, 110=Barcel*/
  UnitOrgId:number;
}
